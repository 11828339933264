import flatpickr from 'flatpickr';

//check add new meeting form on page
if(document.getElementById('add_meeting_form')){
  //bind flatpickr to elements that require it
  const start_date = document.getElementsByName('start_date')[0];
  flatpickr(start_date, {
    altInput: true,
    altFormat: "d/m/Y",
    dateFormat: "Y-m-d",
    minDate: "today"
  });

  //SHOW/HIDE UI IMPROVEMENTS
  function showHideFields(){
    //get elements
    var recurringInput = document.getElementsByName('recurring')[0];
    var recurrenceInput = document.getElementsByName('recurrence')[0];
    var otherRestrictionsInput = document.getElementsByName('other_restrictions')[0];
    var recurrenceGroup = document.getElementsByClassName('input_grp recurrence_grp')[0];
    var dayofweekGroup = document.getElementsByClassName('input_grp day_of_week_grp')[0];
    var weekofmonthGroup = document.getElementsByClassName('input_grp week_of_month_grp')[0];
    var otherrestrictionsdetailsGroup = document.getElementsByClassName('input_grp other_restrictions_details_grp')[0];

    //on recurring change
    recurringInput.addEventListener('change', recurringChange);
    function recurringChange(){
      if(recurringInput.checked){
        recurrenceGroup.classList.remove('hidden');
        dayofweekGroup.classList.remove('hidden');
      } else {
        recurrenceGroup.classList.add('hidden');
        dayofweekGroup.classList.add('hidden');
      }
    }
    //on recurrence change
    recurrenceInput.addEventListener('change', recurrenceChange);
    function recurrenceChange(){
      let recurrence = recurrenceInput.value;
      if(recurrence=='monthly'){
        weekofmonthGroup.classList.remove('hidden');
      } else {
        weekofmonthGroup.classList.add('hidden');
      }
    }

    //on other restrictions change
    otherRestrictionsInput.addEventListener('change', otherRestrictionsChange);
    function otherRestrictionsChange(){
      if(otherRestrictionsInput.checked){
        otherrestrictionsdetailsGroup.classList.remove('hidden');
      } else {
        otherrestrictionsdetailsGroup.classList.add('hidden');
      }
    }

    //hide on load
    // recurrenceGroup.classList.add('hidden');
    // dayofweekGroup.classList.add('hidden');
    weekofmonthGroup.classList.add('hidden');
    otherrestrictionsdetailsGroup.classList.add('hidden');

  }

  showHideFields();
}
